<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
    <div class="container">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow4.png"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <div class="mt-4">
                  <h1 class="font-size-20 mb-3">
                    <strong> Product description: </strong>
                  </h1>
                  <h2>Reflow soldering with best performance</h2>
                  <br />
                  <h5>
                    The Ersa HOTFLOW 4/26 is a high-end reflow soldering system
                    with 7.1 m process length, divided into 26 heating zones and
                    4 cooling zones. Like all systems of the HOTFLOW 4 series,
                    it stands for highest energy efficiency and increased
                    throughput with the usual high soldering quality and process
                    stability. For example, the intelligent nitrogen control
                    reduces consumption by 20%. In addition, the efficient fan
                    motors ensure an overall energy saving of more than 25%.
                  </h5>
                </div>
              </div>
            </div>
            <!-- end row -->

            <br /><br /><br /><br /><br />
            <div class="row">
              <div class="col-xl-6">
                <div class="product-detail">
                  <div class="tab-content" id="v-pills-tabContent">
                    <div
                      class="tab-pane fade active show"
                      id="product-4"
                      role="tabpanel"
                    >
                      <div class="product-img">
                        <img
                          src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/divhotflow4.jpg"
                          alt="img-4"
                          class="img-fluid mx-auto d-block"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end product img -->
              </div>
              <div class="col-xl-6">
                <br /><br /><br /><br />
                <div class="mt-4">
                  <h2>Technology Highlights</h2>
                  <br />
                  <ul class="container">
                    <li>
                      <h5>
                        Individually controllable process zones via
                        energy-optimized motors
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Multi-level controlled cooling</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Multi-level process gas cleaning with pyrolysis</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Rest-oxygen monitoring and control, low N2 consumption
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Ersa Process Control software (EPC)</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Software to generate temperature profiles</h5>
                    </li>
                    <br />
                    <li>
                      <h5>
                        Multi-track conveyor systems (1-4), 1x fixed, 3x
                        variable
                      </h5>
                    </li>
                    <br />
                    <li>
                      <h5>Thermally invisible center supports</h5>
                    </li>
                    <br />
                    <li>
                      <h5>Low energy consumption in continuous operation</h5>
                    </li>
                    <br />
                  </ul>
                </div>
              </div>
            </div>
            <!-- end row -->
            <br /><br />
            <div>
              <b-carousel
                id="carousel-1"
                v-model="slide"
                :interval="2000"
                controls
                indicators
                background="#fff"
                style="text-shadow: 1px 1px 2px #333"
                @sliding-start="onSlideStart"
                @sliding-end="onSlideEnd"
              >
                <div class="carousel-item" active>
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow41.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 4: Energy-efficient motors, perfect air routing
                      and the increased overall efficiency ensure the best
                      energy transfer during heating
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow42.png"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 4: Process lengths from 3.30 to 7.10 m
                    </p>
                  </div>
                </div>

                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow43.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 4: Cooling capacity split according to process
                      requirements enables massive savings in unit and energy
                      consumption
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow44.png"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 4: Cooling in suitable stages
                    </p>
                  </div>
                </div>
                <div class="carousel-item">
                  <div class="parent d-flex justify-content-center">
                    <img
                      class="d-block img-fluid"
                      src="../../../../assets/images/product/main/production_equipement/Reflow_Oven/hotflow45.jpg"
                      alt="category.name"
                    />
                  </div>
                  <br />
                  <div class="parent d-flex justify-content-center">
                    <p class="font-size-10">
                      HOTFLOW 4: The Ersa No-Clean Management for purity in the
                      process zone
                    </p>
                  </div>
                </div>
              </b-carousel>
              <br /><br />
              <!-- video -->
              <div class="row">
                <div class="col-xl-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-xl-8">
                          <!-- 16:9 aspect ratio -->
                          <div class="ratio ratio-21x9">
                            <iframe
                              style="width: 600px; height: 300px"
                              title="YouToube Video"
                              src="https://www.youtube.com/embed/3h8U1oXeRZk"
                              allowfullscreen=""
                            ></iframe>
                          </div>
                        </div>

                        <div class="col-xl-3">
                          <br /><br /><br /><br />
                          <h2>Ersa reflow soldering with the HOTFLOW 4/20</h2>
                          <h5>
                            Reflow soldering with outstanding thermal
                            performance, best energy and nitrogen balance: The
                            Ersa HOTFLOW 4 series sets new records - with 25%
                            overall energy savings and 20% reduced nitrogen
                            consumption.
                          </h5>
                        </div>
                        <!-- end col -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- end col -->
              </div>
            </div>
          </div>
        </div>
        <!-- end card -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../../../components/Header.vue";

import Footer from "../../../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>